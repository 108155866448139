<template>
    <v-btn
        class="px-0 text-none"
        color="primary"
        rounded
        :outlined="outlined"
        @click="click">
        <v-icon v-if="icon" class="app-btn-icon">
            {{ icon }}
        </v-icon>
        <span class="px-5">{{ text }}</span>
    </v-btn>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null,
        },
        text: {
            type: String,
            default: null,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        click: {
            type: Function,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/style/theme.scss";

.app-btn-icon {
    background-color: $color-secondary;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    font-size: 22px;
    margin-right: -5px;
}
</style>
